import { useRouter } from "next/router";
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { authenticate, isAuth, reloadtoken, setCookie, setLocalStorage } from '../actions/auth';
import Header from '../components/Header';
import Private from '../components/Private';
import Panel from '../components/panel/Panel';
import { setSelectedUser, setUsers, setUsersGroup } from '../redux/actions/main';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getCookie } from "../actions/auth";
import { setChatIsVisible } from "../redux/slices/headers";

function Home({ users, selectedUser }) {
	var [usuario, setUsuario] = useState({})
	var [grupo, setGrupo] = useState({})
	var [rol, setRol] = useState()
	const router = useRouter()

	useEffect(() => {
		let user = isAuth()
		let groupi
		if (!user) {
			if (router.query.token && process.browser) {
				setCookie('token', router.query.token)
				user = reloadtoken().then((res) => {
					if (!res || res.error) return
					setLocalStorage('user', res.user)
					return res.user
				})
			}
			return
		}
		setUsuario(user)
		if (user.roles[0] == 'admin') setRol('comercial')
		if (user.roles[0] != 'admin') setRol(user.roles[0])
		if (user.group.ancestors.length > 0) {
			setGrupo(user.group.ancestors[0])
			groupi = user.group.ancestors[0]
		} else {
			setGrupo(user.group)
			groupi = user.group
		}

		reloadtoken().then((res) => {
			if (!res || res.error) return
			if (res.user.group.ancestors.length > 0) {
				setGrupo(res.user.group.ancestors[0])
			} else {
				setGrupo(res.user.group)
			}
			return authenticate(res, () => { })
		})
	}, [router.query])

	return (
		<Private>
			<Header users={users} selectedUser={selectedUser} setUsersGroup={setUsersGroup} set rol={rol} usuario={usuario} slug='statistics'>
				{/* <MenuSuper active={rol == 'comercial' || rol == 'super' || (usuario.roles && (usuario.roles.indexOf('comercial') >= 0 || usuario.roles.indexOf('super') >= 0))} > */}
				<Panel />
				{/* </MenuSuper> */}
			</Header>
		</Private >
	)
}
//  open, children,usersGroup, active

const mapStateToProps = state => ({
	users: state.main.users,
	selectedUser: state.main.selected_user
})

const mapDispatchToProps = {
	setUsers: setUsers,
	setSelectedUser: setSelectedUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
